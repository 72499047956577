import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getfetchSupportCurrencies } from '../lib/api-requests/appConfigs';
import { fetchAllFunds } from 'src/lib/api-requests/funds';

const initialState = {
  sidebarExpanded: true,
  supportedCurrencies: [],
  availableFunds: [],
  hideSideSpacing: false,
};

export const fetchSupportedCurrencies = createAsyncThunk('appConfigs/fetchAvailableCurrencies', async (_) => {
  const result = await getfetchSupportCurrencies();
  return result;
});

export const fetchAvailableFunds = createAsyncThunk('appConfigs/fetchAvailableFunds', async (authToken) => {
  const result = await fetchAllFunds({ queryKey: [, { authToken }] });
  return result.data || [];
});

const slice = createSlice({
  name: 'appConfigs',
  initialState,
  reducers: {
    setSidebarExpandStatus: (state, { payload }) => {
      state.sidebarExpanded = payload;
    },
    setHideSideSpacingStatus: (state, { payload }) => {
      state.hideSideSpacing = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupportedCurrencies.fulfilled, (state, { payload }) => {
      state.supportedCurrencies = payload;
    });
    builder.addCase(fetchAvailableFunds.fulfilled, (state, { payload }) => {
      state.availableFunds = payload;
    });
  },
});

// ACTIONS
export const { setSidebarExpandStatus, setHideSideSpacingStatus } = slice.actions;

// SELECTORS
const selectAppConfigsData = (state) => {
  return state.appConfigs;
};

export const selectSidebarExpanded = createSelector(selectAppConfigsData, (data) => data.sidebarExpanded);

export const selectSupportedCurrencies = createSelector(selectAppConfigsData, (data) => data.supportedCurrencies);
export const selectAvailableFunds = createSelector(selectAppConfigsData, (data) => data.availableFunds);

export const selectHideSideSpacing = createSelector(selectAppConfigsData, (data) => data.hideSideSpacing);

export default slice.reducer;
