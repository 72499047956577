import queryString from 'query-string';
const API_URL = process.env.REACT_APP_SERVER_API_URL;

export const getfetchSupportCurrencies = async () => {
  try {
    const response = await fetch(`${API_URL}/api/currencies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getAllSystemUsers = async ({ queryKey, signal }) => {
  try {
    const [_key, { authToken, params }] = queryKey;
    const url = queryString.stringifyUrl(
      {
        url: `${API_URL}/api/user/all-system-users`,
        query: params,
      },
      { skipNull: true, skipEmptyString: true }
    );
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      signal,
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getSystemRoles = async ({ queryKey }) => {
  try {
    const [_key, { authToken }] = queryKey;
    const response = await fetch(`${API_URL}/api/role`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const assignRoleToUsers = async (authToken, data) => {
  try {
    const response = await fetch(`${API_URL}/api/role/assign-to-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      body: JSON.stringify(data),
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const revokeUserRole = async (authToken, roleId, userId) => {
  try {
    const response = await fetch(`${API_URL}/api/role/revoke/${roleId}/${userId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getUsersWithRole = async ({ queryKey, signal }) => {
  try {
    const [_key, { authToken, roleId, params }] = queryKey;

    const url = queryString.stringifyUrl(
      {
        url: `${API_URL}/api/role/${roleId}/list-users`,
        query: params,
      },
      { skipEmptyString: true, skipNull: true }
    );

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      signal,
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getRolePermissions = async ({ queryKey, signal }) => {
  try {
    const [_key, { authToken, roleId }] = queryKey;

    const response = await fetch(`${API_URL}/api/role/${roleId}/granted-permissions`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      signal,
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getCurrentUserPermissions = async ({ queryKey, signal }) => {
  try {
    const [_key, { authToken }] = queryKey;

    const response = await fetch(`${API_URL}/api/permission/current-user`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      signal,
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const getPermissionsByModule = async ({ queryKey, signal }) => {
  try {
    const [_key, { authToken, name }] = queryKey;

    const response = await fetch(`${API_URL}/api/role/module-availability?module=${name}`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      signal,
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult.data;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const updateRolePermission = async (authToken, roleId, data) => {
  try {
    const response = await fetch(`${API_URL}/api/role/${roleId}/update-permissions`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      body: JSON.stringify(data),
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};

export const addEditPermissionModule = async (authToken, data) => {
  try {
    const response = await fetch(`${API_URL}/api/role/add-module`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': authToken,
      },
      body: JSON.stringify(data),
    });
    const parsedResult = await response.json();
    if (response.ok) {
      return parsedResult;
    }
    throw parsedResult;
  } catch (error) {
    throw error;
  }
};
