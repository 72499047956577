import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import appConfigsReducer from './appConfigs';
import orgSettingsReducer from './orgSettings';

const rootReducer = combineReducers({
  appConfigs: appConfigsReducer,
  orgSettings: orgSettingsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});
