import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, isLoggedIn }) => {
	const location = useLocation();
	let params = new URLSearchParams();
	let hasPermission = false;
	if (isLoggedIn) {
		hasPermission = true;
	} else {
		params.append('redirect', location.pathname);
	}

	return hasPermission ? <Component /> : <Navigate to={`/login?${params.toString()}`} />;
};

export default ProtectedRoute;
