import './scss/style.scss';
import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
// Dayjs for Handling DateTime
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';

import ProtectedRoute from './utils/ProtectedRoute';
import Unauthorized from './components/UnauthorizedComps/Unauthorized';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import { store } from './store';

const queryClient = new QueryClient();

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Logout = React.lazy(() => import('./pages/logout/Logout'));
const Register = React.lazy(() => import('./pages/register/Register'));
const ResetPassword = React.lazy(() => import('./pages/reset-password/ResetPassword'));
const CreatePassword = React.lazy(() => import('./pages/create-password/CreatePassword'));
const VerifyEmail = React.lazy(() => import('./pages/verify-email/index'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Onboarding = React.lazy(() => import('./pages/onboarding'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.updateLocale('en', {
  weekStart: 1,
});

const App = () => (
  <Router>
    <Suspense fallback={<LoadingIndicator />}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <StoreProvider store={store}>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <AppRoutes />
            </QueryClientProvider>
          </AuthProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={true}
            pauseOnHover={true}
            theme="colored"
            toastClassName="font-fam-sfprodisplay text-xsm-4"
          />
        </StoreProvider>
      </GoogleReCaptchaProvider>
    </Suspense>
  </Router>
);

const LoadingIndicator = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AppRoutes = () => {
  const { authToken } = useContext(AuthContext);

  const isLoggedIn = authToken;

  return (
    <Routes>
      <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
      <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <Register />} />
      <Route path="/reset-password" element={isLoggedIn ? <Navigate to="/" /> : <ResetPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/500" element={<Page500 />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<ProtectedRoute isLoggedIn={isLoggedIn} component={DefaultLayout} />} />
    </Routes>
  );
};

export default App;
