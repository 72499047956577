import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => {
    // Retrieve the authentication token from local storage
    const storedToken = localStorage.getItem('authToken');
    return storedToken ? storedToken : '';
  });
  const [userData, setUserData] = useState(() => {
    // Retrieve the authentication userdata from local storage
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  // Function to update authentication data
  const updateAuthData = (token, data) => {
    setAuthToken(token);
    setUserData(data);

    // use to update userdata in the localstorage
    if(localStorage.getItem('userData')) {
      localStorage.setItem('userData', JSON.stringify(data));
    }
  };

  // Function to clear authentication data
  const clearAuthData = () => {
    setAuthToken('');
    setUserData(null);
    localStorage.removeItem('authToken'); // Remove token from local storage
    localStorage.removeItem('userData'); // Remove token from local storage
  };


  useEffect(() => {
    // Store the authentication token & userdata in local storage
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [authToken, userData]);

  useEffect(() => {
    // Check if there is an authentication token and userdata in local storage
    const storedToken = localStorage.getItem('authToken');
    const userData = localStorage.getItem('userData');

    if (storedToken && userData) {
         // Set the token validity period in seconds (e.g., 3 hour)
        const parsedToken = jwt_decode(authToken);
        const tokenValiditySeconds = 10800;

        // Check if the token has expired
        const isTokenExpired = Boolean(Date.now() > (parsedToken.iat + tokenValiditySeconds)* 1000);

        if (isTokenExpired) {
          clearAuthData(); // Clear the authentication data if expired
        }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        userData,
        updateAuthData,
        clearAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
